const isInfo = (errorCode: number): boolean => {
    return errorCode >= 100 && errorCode < 200;
};

const isSuccess = (errorCode: number): boolean => {
    return errorCode >= 200 && errorCode < 300;
};

const isClientError = (errorCode: number): boolean => {
    const isCE = errorCode >= 400 && errorCode < 500;
    console.log(`Is Client error: ${isCE}`, errorCode);
    return isCE;
};

const isApiError = (errorCode: number): boolean => {
    return errorCode >= 500 && errorCode < 600;
};

export { isClientError, isApiError, isSuccess, isInfo };
