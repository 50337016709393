import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EMPTY, Observable, retry, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseErrorHandler } from '@core/interceptors/base.error-handler';
import { isClientError } from '@shared/utils/error.handling.utils';

@Injectable()
export class ClientErrorInterceptor extends BaseErrorHandler implements HttpInterceptor {
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            retry(0),
            catchError((error: HttpErrorResponse) => {
                if (isClientError(error.status)) this.handleClientError(error);
                return throwError(() => error);
            })
        );
    }

    handleClientError(error: HttpErrorResponse) {
        if (error.status === 404) this.handleNotFound(error);
    }

    // 404
    protected handleNotFound(error: HttpErrorResponse) {
        this.showClientError(error.error);
        throwError(() => new Error());
        return EMPTY;
    }
}
