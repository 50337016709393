import { Injectable } from '@angular/core';
import { MessagesService } from '@services/messages.service';
import { HttpErrorResponse, HttpHandler, HttpRequest } from '@angular/common/http';
import { throwError } from 'rxjs';
import { APIErrors, ErrorMessages } from '@shared/enum';

const DEFAULT_REFRESH_URL_BLACKLIST = ['login', 'logout', 'token', 'assets', 'session-refresh'];

@Injectable({
    providedIn: 'root'
})
export class BaseErrorHandler {
    protected blackList: string[] = DEFAULT_REFRESH_URL_BLACKLIST;

    constructor(protected messageService: MessagesService) {}

    showApiError(errorMessage: string) {
        const message = `ERROR_MESSAGES.${errorMessage}`.toUpperCase();
        this.messageService.showError(message, false, 6000);
    }

    showClientError(errorMessage: string) {
        if (typeof errorMessage === 'object') errorMessage = ErrorMessages.GENERAL;
        const message = `ERROR_MESSAGES.${errorMessage}`.toUpperCase();
        this.messageService.showError(message);
    }

    handleApiError(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
        this.showApiError(APIErrors.GENERAL);
        return throwError(() => new Error(error.error));
    }

    protected isBlacklisted(url: string) {
        return this.blackList.some((u) => url.includes(u));
    }
}
